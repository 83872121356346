import React from 'react';
import CardTowns from '../cards/cardTowns';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-cards';

import { Autoplay, EffectCards } from 'swiper/modules';
import { Link } from 'react-router-dom';

function Towns({ villes }) {

    return (
        <div className='container pt-5 overflow-hidden'>
            <div className="row row-cols-1 row-cols-md-2 align-items-center">
                <div className='pt-md-0 px-md-5'>
                    <Swiper
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        effect={'cards'}
                        loop={true}
                        grabCursor={true}
                        modules={[Autoplay, EffectCards]}
                        className="mySwiper"
                    >
                        {villes.map(({ id, town, cover, n_ads, description }) => (
                            <SwiperSlide >
                                <CardTowns
                                    id={id}
                                    town={town}
                                    cover={cover}
                                    count={n_ads}
                                    description={description}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className='pt-3 pt-md-0'>
                    <h2 className="display fw-bold">Découvrez de nouvelles destinations</h2>
                    <p className='lead-1'>
                        Allez à la découverte des bons coins dans chaque ville du Cameroun. 
                        Vous pourriez choisir la ville qui vous intéresse et BookEasily vous propose 
                        plusieurs logements disponibles dans celles-ci ! 
                        <br /> <em>Yaoundé :</em> la capitale politique du Cameroun, connue pour ses monuments historiques, ses marchés et sa vie nocturne.
                        <br /><em>Douala :</em> la capitale économique du Cameroun, célèbre pour ses plages, ses restaurants et ses activités de loisirs.
                        <br /><em>Kribi :</em> la ville balnéaire, célèbre pour ses plages, ses activités de loisirs et son parc national. etc...
                    </p>
                    <Link className='btn btn-warning px-4 fw-bold rounded-5' to="https://wa.me/237695931631">Nous contactez !</Link>
                </div>
            </div>
        </div>
    );
}

export default Towns;
