import styled from 'styled-components'

export const Button = styled.div`
    position: fixed;
    width: auto;
    left: 5%;
    bottom: 5%;
    height: auto;
    font-size: 1rem;
    z-index: 1;
    cursor: pointer;
    padding: 0.6rem;
    font-weight: 600;
    color: white;

    @media (max-width: 540px) {
        left: 1%;
        bottom: 10%;
    }
`
