import React, { useEffect } from "react";
import { useFetch33, useTheme } from "../../../hooks";
import Error from "../../../composants/public/widgets/error";
import Header from "../../../composants/public/sections/header";
import { MainContainer } from "../../../styles/atoms";
import Hero from "../../../composants/public/sections/hero";
import Loading from "../../../composants/public/widgets/loading";
import Explore from "../../../composants/public/sections/explore";
import CallToAction from "../../../composants/public/sections/callToAction";
import Sponsors from "../../../composants/public/sections/sponsors";
import Features from "../../../composants/public/sections/features";
import Search from "../../../composants/public/sections/search";
import Footer from "../../../composants/public/sections/footer";
import ScrollButton from "../../../composants/public/widgets/scrollUp";
import MobileBottomHeader from "../../../composants/public/sections/mobileBottomHeader";
import Newsletter from "../../../composants/public/sections/newsletter";
import Towns from "../../../composants/public/sections/towns";
import Faqs from "../../../composants/public/sections/faqs";

function Home() {
  const { theme } = useTheme();
  const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null;

  // Fetching ads, towns, and categories
  const { isLoading33, data31, data32, data33, error33 } = useFetch33(
    `https://api.book-easily.com/api/ads?per_page=10`,
    `https://api.book-easily.com/api/categories`,
    `https://api.book-easily.com/api/towns`,
    token
  );

  const annonces = data31?.data;
  const categories = data32?.data;
  const towns = data33?.data;

  // Set document title on component mount
  useEffect(() => {
    document.title = "BookEasily | Trouvez votre prochain logement";
  }, []);

  // Error handling
  if (error33) {
    return <Error />;
  }

  return (
    <>
      <Header />
      <MainContainer theme={theme}>
        <Hero />

        {isLoading33 ? (
          <Loading />
        ) : (
          <>
            {/* Services Section */}
            <Search />

            <Features categories={categories} />

            {/* Sponsoring Section */}
            <Sponsors annonces={annonces} />

            {/* CTA Section */}
            <CallToAction />

            <Towns villes={towns}/>

            {/* Explore Section */}
            <Explore />

            <Faqs />

            <Newsletter />
          </>
        )}
      </MainContainer>

      {/* Footer and additional components */}
      <Footer />
      <ScrollButton />
      <MobileBottomHeader />
    </>
  );
}

export default Home;
