import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query"; // React Query v5
import { useTheme } from "../../../hooks";
import Error from "../../../composants/public/widgets/error";
import Header from "../../../composants/public/sections/header";
import {
  GridProperty,
  HeroProperty,
  MainContainer,
  PaginateLink,
  PropertySection,
  StyledTitle1,
} from "../../../styles/atoms";
import Loading from "../../../composants/public/widgets/loading";
import CardAds from "../../../composants/public/cards/cardAds";
import Filter from "../../../composants/public/sections/filter";
import Deals from "../../../composants/public/sections/deals";
import Footer from "../../../composants/public/sections/footer";
import ScrollButton from "../../../composants/public/widgets/scrollUp";
import MobileBottomHeader from "../../../composants/public/sections/mobileBottomHeader";

// Fonction pour récupérer les résultats paginés
const fetchResults = async ({ pageParam = 1, token, path }) => {
  const response = await fetch(
    `https://api.book-easily.com/api/search${path}&per_page=12&page=${pageParam}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=UTF-8",
        Accept: "application/json",
      },
    }
  );
  const data = await response.json();
  return data;
};

function Results() {
  document.title = `BookEasily | Consultez nos logements disponibles`;

  const { theme } = useTheme();
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;
  const path = window.location.href.split("q=")[1];

  // Utilisation de React Query pour récupérer les données paginées
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["results", path], // Correct usage of queryKey in an object
    queryFn: ({ pageParam = 1 }) => fetchResults({ pageParam, token, path }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta?.current_page + 1;
      return lastPage.meta?.current_page < lastPage.meta?.last_page
        ? nextPage
        : false;
    },
  });

  if (isError) {
    return <Error />;
  }

  return (
    <Fragment>
      {/* Section Header */}
      <Header />

      <MainContainer theme={theme}>
        {/* Section Hero */}
        <HeroProperty theme={theme}>
          <div className="row">
            <StyledTitle1 theme={theme}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item fs-2">
                    <Link to="/">
                      <i className="bi bi-house-check-fill text-warning"></i>
                    </Link>
                  </li>
                  <li className="breadcrumb-item fs-2 text-white text-capitalize">Résultats</li>
                </ol>
              </nav>
            </StyledTitle1>
          </div>
        </HeroProperty>

        {/* Loader */}
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            {/* Section des propriétés */}
            <PropertySection>
              <GridProperty>
                {data.pages.map((page) =>
                  page.data.map(
                    ({
                      id,
                      standing,
                      free_booking,
                      free_cancellation,
                      name,
                      designation,
                      category,
                      type,
                      town,
                      street,
                      cover,
                      prices,
                      view,
                      description,
                      is_new,
                      ref,
                      user_first_name,
                      user_photo_url
                    }) => (
                      <CardAds
                        key={ref}
                        standing={standing}
                        free_booking={free_booking}
                        free_cancellation={free_cancellation}
                        designation={designation}
                        id={id}
                        name={name}
                        category={category}
                        type={type}
                        town={town}
                        street={street}
                        cover={cover}
                        price={prices}
                        view={view}
                        description={description}
                        is_new={is_new}
                        reff={ref}
                        user_first_name={user_first_name}
                        user_photo_url={user_photo_url}
                      />
                    )
                  )
                )}
              </GridProperty>
            </PropertySection>

            {/* Pagination */}
            <nav aria-label="..." className="my-3">
              <ul className="pagination justify-content-center">
                {isFetchingNextPage ? (
                  <li className="page-item">
                    <PaginateLink className="page-link">Chargement...</PaginateLink>
                  </li>
                ) : hasNextPage ? (
                  <li className="page-item">
                    <PaginateLink
                      className="page-link"
                      onClick={() => fetchNextPage()}
                    >
                      Voir +
                    </PaginateLink>
                  </li>
                ) : null}
              </ul>
            </nav>

            <Filter countAds={data?.pages[0]?.meta?.total} />
            <Deals />
          </Fragment>
        )}
      </MainContainer>

      {/* Footer */}
      <Footer />
      <ScrollButton />
      <MobileBottomHeader />
    </Fragment>
  );
}

export default Results;
