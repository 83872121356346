import React from "react";
import img from '../../../assets/faq.png'

// Utilisation de React.memo pour éviter les rendus inutiles si les props ne changent pas
function Faqs () {

    return (
        <div class="container py-5">
            <div class="row row-cols-1 row-cols-md-2 align-items-center">
                <div>
                    <h2 class="lead fw-bold">Foire aux questions (FAQs)</h2>
                    <p class="lead-1">Vous avez des questions sur BookEasily ? Nous avons les réponses ! Voici quelques-unes des questions les plus fréquentes que nous recevons, ainsi que nos réponses.</p>
                    <div class="text-center">
                        <img src={img} alt="faq bookeasily" width="100%" />
                    </div>
                </div>
                <div class="accordion accordion-flush" id="accordionFaq">
                    <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            Comment réserver pour une nuitée ?
                        </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFaq">
                        <div class="accordion-body">
                            Pour pouvoir réserver il faut choisir le logement qui vous intéresse et vérifier la disponibilité en entrant l'heure le jour d'arrivée, le jour de départ, le nombre de personnes que vous êtes et le nombre de logements.
                        </div>
                    </div>
                    </div>
                    <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            Dois-je confirmer une réservation ?
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFaq">
                        <div class="accordion-body">
                            Malheureusement non, vous n'avez pas la possibilité de confirmer une réservation.
                        </div>
                    </div>
                    </div>
                    <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            Pourrais-je annuler la réservation d'un client ?
                        </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFaq">
                        <div class="accordion-body">
                            Oui, et vous devez par la suite procéder immédiatement au remboursement total y compris les frais de transaction bancaire si vous avez déjà reçu le virement.
                        </div>
                    </div>
                    </div>
                    <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingFourth">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourth" aria-expanded="false" aria-controls="flush-collapseFourth">
                            Que se passe-t-il si le client ne vient pas ?
                        </button>
                    </h2>
                    <div id="flush-collapseFourth" class="accordion-collapse collapse" aria-labelledby="flush-headingFourth" data-bs-parent="#accordionFaq">
                        <div class="accordion-body">
                            Si la réservation de votre logement est gratuite alors vous ne payez aucune commission. Par contre si elle est payante, vous allez nous verser la commission sur le montant que le client a payé pour effectuer la réservation.
                        </div>
                    </div>
                    </div>
                    <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingFive">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                            Pour une nuitée, pourrais-je choisir mon heure d'arrivée et mon heure de départ ?
                        </button>
                    </h2>
                    <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFaq">
                        <div class="accordion-body">
                            Malheureusement non, vous ne pouvez choisir que la date de départ, la date d'arrivée et préciser le nombre de personnes et de logement que vous voulez.
                        </div>
                    </div>
                    </div>
                    <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingSix">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                            A quoi sert la commission que nous vous donnons ?
                        </button>
                    </h2>
                    <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFaq">
                        <div class="accordion-body">
                            Pour lancer les campagnes marketing pour vous mettre en avant en ligne, proposer des rabais (à nos frais) au client pour les encourager à effectuer une réservation, Assistante disponible 7j/7.
                        </div>
                    </div>
                    </div>
                    <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingSeven">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                            Comment être sûr que ma réservation a abouti ?
                        </button>
                    </h2>
                    <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFaq">
                        <div class="accordion-body">
                            Lorsque vous finalisez votre réservation, nous vous envoyons un message de confirmation par mail que vous pouvez également présenter à l'accueil de l'hébergement.
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faqs;
