import styled from 'styled-components'
import '../../../../styles/globalStyles';

export const Button = styled.a`
    position: fixed;
    width: auto;
    height: auto;
    left: 92%;
    bottom: 5%;
    font-size: 1.5rem;
    z-index: 1;
    cursor: pointer;
    color: white;
    padding: 10px 20px;
    border-radius: 50%;
    animation: blink 2s infinite;

    @media (max-width: 540px) {
        left: 85%;
        bottom: 10%;
    }
`
