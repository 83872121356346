import React, { useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { useTheme } from "../../../hooks";
import { RowSection, StyledTitle, TitlePlusSection } from "../../../styles/atoms";
import CardAds from '../cards/cardAds';

function Sponsors({ annonces }) {
    const { theme } = useTheme();
    
    // Définir le nombre de slides à afficher en fonction de la taille de l'écran
    const [slidesToShow, setSlidesToShow] = useState(1);
    const [isPlaying, setIsPlaying] = useState(true); // Indicateur pour l'autoplay

    // Utiliser useEffect pour ajuster slidesToShow en fonction de la taille de l'écran
    useEffect(() => {
        const updateSlidesToShow = () => {
            const width = window.innerWidth;
            if (width > 1440) {
                setSlidesToShow(4);  // Très grands écrans
            } else if (width > 1080) {
                setSlidesToShow(3);  // Grands écrans
            } else if (width > 768) {
                setSlidesToShow(2);  // Tablettes
            } else {
                setSlidesToShow(1);  // Mobiles
            }
        };

        updateSlidesToShow();
        window.addEventListener('resize', updateSlidesToShow);

        return () => {
            window.removeEventListener('resize', updateSlidesToShow);
        };
    }, []);

    // Configuration du carrousel Embla
    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: true,       // Boucle infinie
        align: 'start',   // Aligner à gauche (ou centré si nécessaire)
        skipSnaps: false, // Ne pas ignorer les snaps
        slidesToScroll: slidesToShow,  // Définit combien de slides défilent
    });

    // Filtrer les annonces visibles
    const visibleAnnonces = annonces.filter(ad => ad.visibility === 1);

    // // Fonction pour passer au slide suivant
    // const scrollNext = useCallback(() => {
    //     if (emblaApi) emblaApi.scrollNext();
    // }, [emblaApi]);

    // // Fonction pour revenir au slide précédent
    // const scrollPrev = useCallback(() => {
    //     if (emblaApi) emblaApi.scrollPrev();
    // }, [emblaApi]);

    // Autoplay - Utilise un intervalle pour faire défiler automatiquement les slides
    useEffect(() => {
        if (!emblaApi || !isPlaying) return;

        const autoplay = setInterval(() => {
            emblaApi.scrollNext();
        }, 10000); // 3000ms = 3 secondes entre chaque slide

        // Nettoyer l'intervalle quand le carrousel change ou l'utilisateur interagit
        return () => clearInterval(autoplay);
    }, [emblaApi, isPlaying]);

    // Stop l'autoplay quand l'utilisateur interagit
    const handleUserInteraction = () => {
        setIsPlaying(false); // Stop autoplay lors de l'interaction
    };

    return (
        <div className='container py-5'>
            <TitlePlusSection>
                <StyledTitle theme={theme} className='visually-hidden'>
                    Recommandations
                    <i className="bi bi-stars text-warning"></i>
                </StyledTitle>
                <p className="visually-hidden">
                    Découvrez à l’accueil de BookEasily les catégories d’hébergements à savoir hôtels, meublés, locations/non meublés.
                </p>
            </TitlePlusSection>
            <RowSection>
                <div className="col-12 embla" ref={emblaRef} onMouseEnter={handleUserInteraction}>
                    <div className="embla__container">
                        {visibleAnnonces.map((ad) => {
                            const {
                                standing, id, designation, free_booking, free_cancellation,
                                name, category, type, town, street, cover, prices,
                                view, description, is_new, ref, user_photo_url, user_first_name
                            } = ad;
                            return (
                                <div className="embla__slide" key={ref} style={{ flex: `0 0 ${100 / slidesToShow}%` }}>
                                    <CardAds
                                        standing={standing}
                                        free_booking={free_booking}
                                        free_cancellation={free_cancellation}
                                        designation={designation}
                                        id={id}
                                        name={name}
                                        category={category}
                                        type={type}
                                        town={town}
                                        street={street}
                                        cover={cover}
                                        price={prices}
                                        view={view}
                                        description={description}
                                        is_new={is_new}
                                        reff={ref}
                                        user_photo_url={user_photo_url}
                                        user_first_name={user_first_name}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
                {/* <button onClick={scrollPrev} className="embla__prev">Précédent</button>
                <button onClick={scrollNext} className="embla__next">Suivant</button> */}
            </RowSection>
        </div>
    );
}

export default Sponsors;
