import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { LogSection, HomeLogo } from "../../styles/atoms";
import logo from '../../assets/logo.png';
import HelpButton from "../../composants/public/widgets/helpButton";

function Login() {
    useEffect(() => {
        document.title = `BookEasily | Se connecter`;
    }, []);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);

        try {
            const response = await fetch('https://api.book-easily.com/api/login', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                redirect: 'manual',
                headers: {
                    'Accept': 'application/json',
                },
                body: formData,
            });

            const data = await response.json();
            setStatus(data?.status);

            if (data?.status === 1) {
                localStorage.setItem("token", JSON.stringify(data?.data.token));
                localStorage.setItem("user", JSON.stringify(data?.data.user));
                const xsrfToken = document.cookie.split(';').find(c => c.trim().startsWith('xsrf_token='));
                localStorage.setItem("cookies", xsrfToken ? xsrfToken.split('=')[1] : '');

                window.location.assign(data.data.user.email_verified === 1 ? "/dashboard" : "/email/verification-notification");
            }
        } catch (error) {
            console.error('Login error:', error);
            setStatus(0);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <LogSection>
                <div className='container justify-content-center col-md-6'>
                    <form className="my__form g-2 p-3" onSubmit={handleLogin}>
                        <div className="col-md-12 text-center">
                            <Link to="/">
                                <HomeLogo src={logo} alt="logo BookEasily" />
                            </Link>
                        </div>
                        {status === 1 && (
                            <div className="alert alert-info text-center" role="alert">
                                Vous êtes de nouveau connecté {JSON.parse(localStorage.getItem("user"))?.first_name} !
                            </div>
                        )}
                        {status === 0 && (
                            <div className="alert alert-danger text-center" role="alert">
                                Vérifiez à nouveau vos informations.
                            </div>
                        )}
                        <div className="col-md-12 py-1">
                            <i className="bi bi-box-arrow-up-left text-warning"></i> <Link to="/" className='text-warning'> Accueil</Link>
                        </div>
                        <div className="col-md-12 py-1">
                            <input
                                type="email"
                                className="form-control rounded-5"
                                id="email"
                                name="email"
                                placeholder="Entrez votre email..."
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <small className="text-muted">Email *</small>
                        </div>
                        <div className="col-md-12 py-1">
                            <input
                                type="password"
                                className="form-control rounded-5"
                                id="password"
                                name="password"
                                placeholder="Entrez votre mot de passe..."
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <small className="text-muted">Mot de passe *</small>
                        </div>
                        <div className="col-12 my-1 text-warning">
                            * = champ obligatoire
                        </div>
                        <div className="col-12 text-end">
                            <Link to="/" className="text-warning">Mot de passe oublié ?</Link>
                        </div>
                        <div className="col-12 text-end my-3">
                            {loading ? (
                                <button className="btn btn-outline-warning fw-bold w-100 rounded-5" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> En cours...
                                </button>
                            ) : (
                                <button type="submit" className="btn btn-outline-warning fw-bold w-100 rounded-5">
                                    Connexion
                                </button>
                            )}
                        </div>
                        <div className="col-12">
                            <p className="text-muted">Vous n'avez pas de compte ? <Link to='/sign-up' className="text-warning">Créer un compte !</Link></p>
                        </div>
                    </form>
                </div>
            </LogSection>
            <HelpButton />
        </Fragment>
    );
}

export default Login;
