import React from 'react';
import I1 from "../../../assets/2.jpg";
import I2 from "../../../assets/12.jpg";
import I6 from "../../../assets/50.jpg";
import I3 from "../../../assets/22.jpg";
import I4 from "../../../assets/25.jpg";
import I5 from "../../../assets/Bookeasily.jpg";
import { CatLink } from "../../../styles/atoms";

function Explore () {

    const images = [
        {
            id: '1',
            title: "Proche d'un site touristique",
            img: I1,
            link: `/results/q=?near_tourist_area=1`
        },
        {
            id: '3',
            title: "Fêtes autorisées",
            img: I6,
            link: `/results/q=?have_party=1`
        },
        {
            id: '4',
            title: "Réservation gratuite", 
            img: I3,
            link: `/results/q=?free_booking=1`
          }, 
          {
            id: '5',
            title: "Accessible aux handicapés", 
            img: I4,
            link: `/results/q=?with_disability=1`
          },
          {
              id: '2',
              title: "Proche d'une plage",
              img: I2,
              link: `/results/q=?near_beach=1`
          },
          {
            id: '6',
            title: "Découvrez nos Bons Plans", 
            img: I5,
            link: `/best_deals`
          }
    ];

    return (
        <div className='container pt-5'>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 align-items-stretch gy-3 pb-2">
                {images.map(({ id, title, img, link }) => (
                    <CatLink to={link} className="col" key={id}>
                        <div className="card h-100 text-bg-dark" 
                             style={{ 
                                 backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(${img})`,
                                 backgroundPosition: 'center center',
                                 backgroundSize: 'cover' 
                             }}>
                            <div className="d-flex flex-column h-100 px-3 pt-5 pb-4 mt-5">
                                <h4 className="pt-3 mt-5 fw-bold">{title} <i className="bi bi-arrow-right-circle"></i></h4>
                            </div>
                        </div>
                    </CatLink>
                ))}
            </div>
        </div>
    );
};

export default Explore;
