import { Fragment, useEffect } from "react";
import Header from "../../../composants/public/sections/header";
import { MainContainer, StyledLink } from "../../../styles/atoms";
import { useTheme } from "../../../hooks";
import Footer from "../../../composants/public/sections/footer";
import MobileBottomHeader from "../../../composants/public/sections/mobileBottomHeader";
import Faqs from "../../../composants/public/sections/faqs";
import Newsletter from "../../../composants/public/sections/newsletter";
import HelpButton from "../../../composants/public/widgets/helpButton";
import { Link } from "react-router-dom";
import img1 from '../../../assets/1.jpg';
import img2 from '../../../assets/2.jpg';

function Partners() {
  const { theme } = useTheme();
  const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;

  useEffect(() => {
    document.title = 'BookEasily | Devenez partenaire';
    const desc = document.querySelector('meta[name="description"]');
    if (desc) {
      desc.setAttribute('content', 'BookEasily | Devenez partenaire et boostez votre visibilité et vos réservations');
    }
  }, []);

  const getLink = (path) => (user ? `/dashboard/categories/${path}/new_ads` : '/sign-in');

  return (
    <Fragment>
      <Header />
      <MainContainer theme={theme}>
        <div className="bg-light pb-5 pt-3">
          <div class="container">
              <div class="row row-cols-1 row-cols-md-2 align-items-center hero-partner">
                  <div class="text order-last order-md-first">
                      <h2 className="fw-bold lead">Rejoignez la communauté <span className="text-warning">BookEasily</span></h2>
                      <p className="card-text">
                      Augmentez votre visibilité et vos chances de location avec BookEasily ! Publiez votre annonce gratuitement et atteignez un large public de locataires potentiels. Gérez vos réservations en ligne et optimisez vos revenus locatifs avec notre plateforme sécurisée et facile à utiliser !
                        </p>
                        <p className="card-text fw-bold">Facile et rapide :</p>
                        <ul>
                          <li>Créez votre compte en moins d'une minute,</li>
                          <li>Ajoutez vos biens avec des photos et descriptions attractives,</li>
                          <li>Fixez vos prix et votre calendrier de disponibilité, et</li>
                          <li>Commencez à recevoir des réservations</li>
                        </ul>
                      <div>
                          <Link class="btn btn-outline-warning fw-bold" to="https://wa.me/237695931631">Nous contactez !</Link>
                          <div className="btn-group btn btn-warning px-2 my-2 my-md-0 mx-0 mx-md-2 pt-2 pt-md-0 fw-bold">
                            <button className="btn p-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="true">
                              <i className="bi bi-bag-plus"></i> <StyledLink className="fw-bold text-dark">Ajoutez votre annonce</StyledLink>
                            </button>
                            <ul className="dropdown-menu">
                              <li className="dropdown-item">
                                <StyledLink to={getLink('hotels')}><i className="bi bi-buildings"></i> Hotel</StyledLink>
                              </li>
                              <li className="dropdown-item">
                                <StyledLink to={getLink('meubles')}><i className="bi bi-building"></i> Meublé</StyledLink>
                              </li>
                              <li className="dropdown-item">
                                <StyledLink to={getLink('non meubles')}><i className="bi bi-house-door"></i> Location/Non Meublé</StyledLink>
                              </li>
                            </ul>
                          </div>
                      </div>
                  </div>
                  <div class="images order-first order-md-last">
                      <div class="item first">
                          <img src={img2} alt=""/>
                      </div>
                      <div class="item second">
                          <img src={img1} alt=""/>
                      </div>
                  </div>
              </div>
          </div>

        </div>

        <Faqs />

        <Newsletter />
      </MainContainer>
      <Footer />
      <HelpButton />
      <MobileBottomHeader />
    </Fragment>
  );
}

export default Partners;
