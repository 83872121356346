import styled from 'styled-components'

export const Button = styled.div`
    position: fixed;
    width: 100%;
    left: 95%;
    bottom: 5%;
    height: 50px;
    font-size: 2rem;
    z-index: 1;
    cursor: pointer;
    color: #df9600;

    @media (max-width: 540px) {
        bottom: 10%;
        left: 88%;
    }
`
