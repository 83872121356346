import React from "react";
import { CatLink } from "../../../styles/atoms";

// Utilisation de React.memo pour éviter les rendus inutiles si les props ne changent pas
const CardTowns = React.memo(({ id, cover, town, count, description }) => {
    
    // Gestion des erreurs d'image : Affichage d'une image par défaut en cas d'erreur
    const handleImageError = (e) => {
        e.target.style.backgroundImage = "url('https://api.book-easily.com/storage/assets/NoImage.jpg')";
    };

    return (
        <CatLink to={`/properties/towns/${town}/${id}`} key={`${id}-${town}`}>
            <div className="row g-0">
                <div className="col">
                    <div 
                        className="card text-bg-dark" 
                        style={{
                            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url(${cover})`,
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat'
                        }}
                        onError={handleImageError} // Gestion des erreurs d'image
                    >
                        <div className="d-flex flex-column pt-5 pb-3 mt-5 px-3">
                            <h4 className="pt-5 mt-5 lead-5 fw-bold">
                                {town}
                                {count > 0 ? (
                                    <span className="card-text lead-1"> (+ {count} annonces)</span>
                                ) : (
                                    <span className="card-text lead-1"> (à venir)</span>
                                )}
                            </h4>
                            <ul className="d-flex list-unstyled mt-auto">
                                <li className="me-auto">
                                    <p className="card-text lead-1">{description}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </CatLink>
    );
});

export default CardTowns;
