import styled from 'styled-components';
import '../../../../styles/globalStyles';

export const Button = styled.a`
    position: fixed;
    width: 100%;
    left: 95%;
    bottom: 12%;
    height: 50px;
    font-size: 2rem;
    z-index: 1;
    cursor: pointer;
    color: #df9600;
    font-weight: 700;
    animation: blink 2s infinite;

    &:active, &:hover {
        color: #df9600;
    }

    @media (max-width: 540px) {
        bottom: 18%;
        left: 88%;
    }
`
