import { useState } from 'react'

export default function Newsletter() {
    const [email, setEmail] = useState('')
    const x_xsrf_token = localStorage.getItem("cookies")
  
    const forms = new FormData()
    forms.append('email', email)

      
    async function handleSubmit(e, forms) {
        e.preventDefault()

        await fetch('https://api.book-easily.com/api/nletter', {
            method: "POST",
            mode: "cors",
            cache: "default",
            credentials: "include",
            redirect: 'manual',
            body: forms,
            headers: {
                'Accept': 'application/json; charset=UTF-8',
                'X-XSRF-TOKEN': `${x_xsrf_token}`
            },
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.status === 1) {
                window.location.reload()
            }
        })
        .catch((err) => {
          console.log(err.message)
        })
    } 

    return (
        <div className="container">
            <div className="newsletter text-center py-5 px-md-5 border rounded bg-light">
                <h2 className="lead-5 fw-bold">Newsletters</h2>
                <p className='lead-1 my-3'>Inscrivez-vous à notre newsletter et restez informé des dernières actualités, des offres spéciales 
                        et des conseils de voyage pour votre prochain séjour au Cameroun et bien plus !</p>
                <form onSubmit={(e) => handleSubmit(e, forms)}>
                    <div className="d-flex mx-2 border-warning border rounded-5">
                        <label htmlFor="email" className="visually-hidden">Email</label>
                        <input id="email" name="email" type="email" onChange={(e) => setEmail(e.target.value)} className="form-control border-light rounded-5 bg-light" placeholder="Email" />
                        <button className="btn btn-warning rounded-5 fw-bold" type="submit">Inscription</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

        