import React from 'react';
import { CatLink } from "../../../styles/atoms"
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-cards';

import { Autoplay, EffectCards } from 'swiper/modules';
import { Link } from 'react-router-dom';

function Features ({categories}){

    return ( 
    <div className="container overflow-hidden">
        <div className="row row-cols-1 row-cols-md-2 align-items-center">
            <div className='order-last order-md-first pt-md-0 pt-3'>
                <h2 className="display fw-bold">A la découverte de BookEasily</h2>
                <p className="lead-1">Chez BookEasily, nous proposons une variété de logements pour répondre à tous vos besoins et budgets. Découvrez nos catégories de logements :

                    <br /><em>Hôtels :</em> des hôtels confortables et modernes, idéaux pour les séjours courts ou longs. Nos hôtels sont équipés de tout le nécessaire pour un séjour agréable, notamment des chambres climatisées, des salles de bain privées, des téléviseurs et des connexions Wi-Fi.
                    <br /><em>Appartements meublés :</em> des appartements entièrement meublés et équipés, parfaits pour les séjours prolongés ou pour les familles. Nos appartements meublés disposent de cuisines équipées, de salles de bain privées, de téléviseurs et de connexions Wi-Fi.
                    <br /><em>Locations de vacances :</em> des maisons et des appartements et studios pour les familles.
                </p>
                <Link className='btn btn-warning  px-4 fw-bold rounded-5' to="https://wa.me/237695931631">Nous contactez !</Link>
            </div>
            <div className='order-first order-md-last pt-md-0 px-md-5'>
                <Swiper
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    effect={'cards'}
                    loop={true}
                    grabCursor={true}
                    modules={[Autoplay, EffectCards]}
                    className="mySwiper"
                >
                    {categories.map(({id, name, image, description, n_ads}) => (
                        <SwiperSlide>
                            <CatLink to={`/properties/categories/${name}/${id}`} key={id}>
                                <div className="card overflow-hidden text-bg-dark rounded-4" style={{backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.6)), url(${image})`, backgroundPosition: 'center center', backgroundSize: 'cover', height: '300px'}}>
                                    <div className="d-flex flex-column px-5 pt-5 mt-5 text-shadow-1">
                                        <h4 className="pt-5 mt-5 lead-5 fw-bold">
                                            {name}
                                            { n_ads > 0 ? 
                                                    <span className="card-text lead-1"> (+ {n_ads} annonces)</span>
                                                :
                                                    null
                                            }
                                        </h4>
                                        <ul className="d-flex list-unstyled mt-auto">
                                            <li className="me-auto">
                                                <p className="card-text lead-1">{description}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </CatLink>
                        </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    </div>
    )
}

export default Features
