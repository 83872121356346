import { Link } from 'react-router-dom'
import styled from 'styled-components'
import CTA from '../assets/24.jpg'
import CAT from '../assets/33.jpg'
import User from '../assets/Bookeasily.jpg'
import colors from './colors'

export const StyledLink = styled(Link)`
  padding: 0.8rem 0rem 0.8rem 0rem;
  color: ${colors.dark};
  text-decoration: none;
  font-size: 0.9rem;
  text-align: start;
  font-weight: 600;

  &:hover {
    color: ${colors.primary};
  }
`

export const CatLink = styled(Link)`
  text-decoration: none;
  text-transform: capitalize;
  text-align: start;
  font-size: 0.9rem;

  &:hover {
    color: ${colors.primary};
  }
`

export const NavLink = styled(Link)`
  padding: 0.2rem;
  color: ${colors.dark};
  text-decoration: none;
  font-size: 1rem;
  text-align: start;
  font-weight: 600;

  &:hover {
    color: ${colors.primary};
  }
`

export const PaginateLink = styled(Link)`
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  color: white;
  background-color: ${colors.primary};
  
  &:hover {
    color : ${colors.primary};
    background-color: ${colors.white};
    transform: scale(1.05);
  }

  ${(props) =>
    props.active &&
    `color : ${colors.primary};`
  }
`

export const FooterLink = styled(Link)`
  padding: 0.2rem;
  text-decoration: none;
  font-size: 0.9rem;
  text-align: start;
  font-weight: 500;

  &:hover {
    color : ${colors.primary}
  }
`

export const SearchForm = styled.form`
  display: flex;

  @media (max-width: 540px) {
    display: none;
  }

  @media (max-width: 309px) {
    display: flex;
  }

`

export const MobileSearch = styled.form`
  display: none;

  @media (max-width: 540px) {
    display: flex;
    width: 10rem;
  }

  @media (max-width: 309px) {
    display: none;
  }
`

export const LogoutButton = styled.button`
    padding: 0.8rem 1rem 0.8rem 0rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    font-size: 0.8rem;
    text-align: start;
    font-weight: 600;
`

export const DeleteButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: red;
    text-decoration: none;
    font-size: 0.8rem;
    text-align: start;
    font-weight: 600;
`

export const NightModeButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: ${colors.primary};
`

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
`

export const MainContainer = styled.div`
  background-color: ${({ theme }) =>
    theme === 'light' ? colors.backgroundLight : colors.backgroundDark };
  margin : 0;
  width : 100%;
`

export const HeaderContainer = styled.header`
  padding: 1.5rem 4rem 1.5rem 4rem;
  margin : 0;
  width : 100%;
  background-color: ${({ theme }) =>
    theme === 'light' ? colors.backgroundLight : colors.backgroundDark };

  @media (max-width: 540px) {
    padding: 1.5rem 1rem;
  }
`

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    margin : 0;
    width : 100%;

    @media (max-width: 540px) {
      padding-bottom: 4rem;
    }
`

export const StyledTitle1 = styled.h1`
  color: ${({ theme }) => (theme === 'light' ? colors.dark : colors.primary )};
  font-weight: 600;
  padding: 0.3rem 0rem 0.3rem 0rem;
  font-size: 1.4rem;
  
  &:hover {
    color: ${colors.primary};
  }
`

export const StyledTitle = styled.h2`
  color: ${({ theme }) => (theme === 'light' ? colors.dark : colors.primary )};
  font-weight: 600;
  text-transform: capitalize;
  text-decoration: underline;
  padding: 0.3rem 0rem 0.3rem 0rem;
  text-decoration-offset: 0.2rem;
  text-decoration-thickness: 0.1rem;
  font-size: 1.2rem;

  &:hover {
    color: ${colors.primary};
  }
`
export const StyledTitle2 = styled.h3`
  color: ${({ theme }) => (theme === 'light' ? colors.dark : colors.primary )};
  font-weight: 600;
  padding: 0.3rem 0rem 0.3rem 0rem;
  font-size: 1rem;
`
export const StyledTitle3 = styled.h4`
  color: ${({ theme }) => (theme === 'light' ? colors.dark : colors.primary )};
  font-weight: 600;
  padding: 0.3rem 0rem 0.3rem 0rem;
  font-size: 1rem;
`
export const StyledText = styled.p`
  color: ${({ theme }) => (theme === 'light' ? colors.dark : colors.light )};
  font-size: 0.8rem;
` 


export const FooterSection = styled.footer`
    padding: 2rem 4rem 2rem 4rem;
    border-top: 1px solid #E3E2D4;
    margin : 0;
    width : 100%;

    @media (max-width: 1080px) {
        padding: 1.5rem 1rem;
      }

    @media (max-width: 540px) {
        padding: 1.5rem 1rem;
    }
`

export const TownSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem 3rem 2rem 3rem;
  margin : 0;
  width : 100%;

  @media (max-width: 1080px) {
    padding: 1.5rem 1rem;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 540px) {
    padding: 1rem 0.72rem 1rem 0.72rem;
  }
`

export const HelpSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem 3rem 2rem 3rem;
  margin : 0;
  width : 100%;
  

  @media (max-width: 1080px) {
    padding: 1.5rem 1rem;
  }

  @media (max-width: 720px) {
    flex-direction: column;
  }

  @media (max-width: 540px) {
    padding: 1rem 0.72rem 1rem 0.72rem;
  }
`

export const ContactSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem 4rem 2rem 4rem;
  border-top: 1px solid #E3E2D4;
  margin : 0;
  width : 100%;

  @media (max-width: 1080px) {
    padding: 1.5rem 1rem;
  }

  @media (max-width: 540px) {
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 1rem;
  }
`

export const ErrorSection = styled.div`
  padding: 2rem 4rem 2rem 4rem;
  background-color: ${( {theme} ) =>
  theme === 'light' ? colors.darkSection : colors.backgroundLight};
  color: ${( {theme} ) =>
  theme === 'light' ? colors.light : colors.dark};

  @media (max-width: 1080px) {
    padding: 1.5rem 1rem;
  }

  @media (max-width: 540px) {
    padding: 1.5rem 1rem;
  }
`

export const HeroSection = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2rem 4rem 2rem 4rem;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.6)
    ), url(${CTA});
    background-position: center center;
    margin : 0;
    width : 100%;
    background-size: cover;


    @media (max-width: 1080px) {
        padding: 2rem;
    }

    @media (max-width: 540px) {
        padding: 1.5rem 1rem;
    }
`

export const CartSection = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1.5rem 4rem 2rem 4rem;
    margin : 0;
    width : 100%;

    @media (max-width: 1080px) {
      padding: 1.5rem 1rem;
    }

    @media (max-width: 920px) {
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: 540px) {
    padding: 1rem 0.72rem 1rem 0.72rem;
    }
` 

export const PlusSection = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem 3rem 2rem 3rem;
    margin : 0;
    width : 100%;
    
    border-top: 1px solid #E3E2D4;

    @media (max-width: 1080px) {
      padding: 1.5rem 1rem;
    }

    @media (max-width: 540px) {
      flex-direction: column;
      align-items: center;
      padding: 1rem 0.72rem 1rem 0.72rem;
    }
` 
export const TitleSection = styled.div`
    padding: 0rem;
`

export const TitlePlusSection = styled.div`
    padding: 0rem;
`

export const PropertySection = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2rem 3rem 1rem 3rem;
    margin : 0;
    width : 100%;

    @media (max-width: 1080px) {
      padding: 1rem 2rem 1rem 2rem;
    }

    @media (max-width: 720px) {
      flex-direction: column;
    }

    @media (max-width: 540px) {
      flex-direction: column;
      align-items: center;
      padding: 1rem 0.72rem 1rem 0.72rem;
    }
`

export const GridProperty = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  margin : 0;
  gap: 0.2rem;
  width : 100%;

  @media (max-width: 1440px) {
  }

  @media (max-width: 1024px) {
  }

  @media (max-width: 540px) {
  }
`

export const SponsorSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem 2rem 3rem;
  margin : 0;
  width : 100%;

  @media (max-width: 1080px) {
    padding: 1.5rem 1rem;
  }

  @media (max-width: 540px) {
    padding: 1rem 0.72rem 1rem 0.72rem;
  }
`

export const RowSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin : 0;
  width : 100%;

  @media (max-width: 1440px) {
  }

  @media (max-width: 1080px) {
  }

  @media (max-width: 540px) {
    flex-direction: column;
  }
`

export const AnnonceSection = styled.section`
  padding: 1.5rem 4rem 1.5rem 4rem;
  margin : 0;
  width : 100%;

  @media (max-width: 1080px) {
    padding: 1.5rem 1rem;
  }

  @media (max-width: 540px) {
    padding: 1.5rem 1rem;
  }
`

export const ServiceSection = styled.section`
  padding: 0rem 4rem 0rem 4rem;
  margin : 0;
  width : 100%;
  background-color: ${({ theme }) =>
    theme === 'light' ? colors.backgroundLight : colors.backgroundDark };

  @media (max-width: 1080px) {
    padding: 1.5rem 1rem;
  }

  @media (max-width: 540px) {
    padding: 1.5rem 1rem;
  }
`

export const AdminSection = styled.section`
  display : flex;
  flex-direction : row;
  justify-content: center;
  padding: 1rem 4rem 1rem 4rem;
  margin : 0;
  width : 100%;
  gap: 0.5rem;

  @media (max-width: 1080px) {
    padding: 1.5rem 1rem;
  }

  @media (max-width: 540px) {
    flex-direction : column;
    padding: 1.5rem 1rem;
  }
`

export const CtaSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 5rem 2.5rem 5rem;
    margin: 1.5rem 0rem 1.5rem 0rem;
    width : 100%;
    height: auto;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.6)
    ), url(${CTA});
    background-position: center center;
    background-size: cover;

    @media (max-width: 1080px) {
    padding: 2rem 1rem;
    }
`

export const BadgeSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem 4rem 4rem 4rem;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.6)
    ), url(${CAT});
    background-position: center bottom;
    margin : 0;
    width : 100%;
    background-size: cover;

    @media (max-width: 1080px) {
        padding: 3rem 1rem 1.5rem 1rem;
    }

    @media (max-width: 540px) {
        padding: 5rem 1rem 3rem 1rem;
    }
`

//Error
export const ErrorWrapper = styled.div`
  padding: 4rem 1rem 2rem 1rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) =>
    theme === 'light' ? colors.backgroundLight : colors.backgroundDark};
  align-items: center;
  margin : 0;
  width : 100%;

  @media (max-width: 540px) {
    padding: 1.5rem 1rem;
  }
`

export const ErrorTitle = styled.h6`
  color: ${({ theme }) => (theme === 'light' ? '#000000' : '#ffffff')};
  font-weight: 500;
  text-align: center;
  padding-top: 2rem;
  font-size: 0.8rem;
`

export const ErrorSubtitle = styled.h2`
  color: ${({ theme }) => (theme === 'light' ? colors.dark : colors.primary)};
  font-weight: 500;
  font-size: 1.4rem;
`

export const Illustration = styled.img`
  max-width: 360px;
`

//Header
export const HomeLogo = styled.img`
  height: 3rem;

  &:hover {
    transform: scale(1.005);
  }
`

//Carroussel
export const ImgCarrousel = styled.img`
  height: 300px;
  width: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center center;

  @media (max-width: 540px) {
  }
`

export const SpanCarrousel = styled.span`
  position: relative;
  color: #ffffff;
  padding: 10px;
  right: 0;
  top: 10px;
  font-weight: 600;
  font-size: 0.8rem;
  background-color: green;
  border-radius: 3px;
  background-color: green;
  text-transform: capitalize;
`

//Cart
export const ImgCard = styled.img`
  height: 300px;
  width: 100%;
  object-fit: cover;
  background-position: center center;
  border-radius: 10px 10px 0 0;
  position: relative;
  
  &:hover {
    transform: scale(1.005);
  }
`

export const SpanCartDiscount = styled.span`
  position: absolute;
  top: 0%;
  right: 0%;
  border-radius: 0 10px 0 10px;
  padding: 10px;
  color: #ffffff;
  font-weight: 700;
  background-color: #ff0000;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  font-size: 0.9rem;
`

export const SpanCartNew = styled.span`
  position: absolute;
  color: #ffffff;
  top: 261px;
  left: 0;
  border-radius: 0 3px 0 3px;
  padding: 10px;  
  font-weight: 600;
  background-color: green;
  font-size: 0.8rem;
`

export const SpanPriceUpsell = styled.span`
  position: absolute;
  color: #ffffff;
  top: 261px;
  left: 0;
  border-radius: 0 3px 0 3px;
  padding: 10px;  
  font-weight: 600;
  background-color: green;
  font-size: 0.8rem;
`

export const SpanCartStanding = styled.span`
  position: absolute;
  bottom: 1%;
  right: 0;
  background-color: transparent;
  font-size: 1.5rem;
`

export const CardDescription = styled.h6`
  text-overflow: ellipsis;
  with-space: nowrap;
  overflow: hidden;
  font-size: 0.8rem;
`

//CartSingle
export const ImgCardSingle = styled.img`
  height: 400px;
  width: 100%;
  object-fit: contain;
  background-size: cover;
  background-position: center center;
  background-color: #fff9e3;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  @media (max-width: 540px) {
  height: 350px;
}
`
export const ImgCardSingleThumb = styled.img`
  height: 100px;
  width: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
  background-color: #fff9e3;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

}
`

export const ImgCardUpsell = styled.img`
  height: 300px;
  width: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
`

//Features
export const SpanCategory = styled.span`
  position: relative;
  bottom: -10px;
  color: #ffffff;
  padding: 10px;
  font-weight: 600;
  font-size: 0.8rem;
  background-color: #df9600;
  text-transform: capitalize;
  border-radius: 3px;
`



//Hero

// background-color: ${( {theme} ) =>
// theme === 'light' ? colors.darkSection : colors.backgroundLight};


export const HeroTitle = styled.h1`
  color: ${({ theme }) => (theme === 'light' ? colors.light : colors.dark )};
  font-weight: 700;
  padding-bottom: 1rem;
  font-size: 2rem;
  
  @media (max-width: 540px) {
    font-size: 1.2rem;
  }
`
export const HeroText = styled.p`
  color: ${({ theme }) => (theme === 'light' ? colors.light : colors.dark )};
  padding-bottom: 1rem;
  font-size: 1rem;
`



//Property
export const HeroProperty = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5rem 4rem 3rem 4rem;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.6)
  ), url(${CTA});
  background-position: center left;
  margin : 0;
  width : 100%;
  background-size: cover;

  @media (max-width: 1080px) {
    padding: 4rem 4rem 2rem 4rem;
  }
`

export const GridHelp = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin : 0;
  gap: 0.5rem;
  width : 100%;

  @media (max-width: 1080px) {
    grid-template-columns : 1fr 1fr;
  }

  @media (max-width: 540px) {
    grid-template-columns : 1fr;
  }
`

//Admin
export const GridBoardForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin : 0;
  gap: 0.5rem;
  width : 100%;

  @media (max-width: 540px) {
    grid-template-columns : 1fr;
  }
`


export const TD = styled.td`
  color: ${(colors.dark)};
  text-align: center;
`

export const HeroAdminSection = styled.section`
  padding: 3rem 4rem 0rem 4rem;
  margin: 0;
  width : 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.6)
  ), url(${User});
  background-position: center center;
  background-size: cover;

  @media (max-width: 540px) {
      padding: 2rem 1rem 0rem 1rem;
  }
`

export const HeroTitleAdmin = styled.h1`
  color: ${({ theme }) => (theme === 'light' ? colors.light : colors.dark )};
  font-weight: 600;
  text-transform : capitalize;
  font-size: 1.2rem;
`



export const BoardSection = styled.div`
  display: grid;
  grid-template-columns : 1fr 1fr 1fr 1fr;
  background-color: ${colors.light};
  margin: 1.2rem 0rem 1rem 0rem;
  width : 100%;
  
  @media (max-width: 1024px) {
    grid-template-columns : 1fr 1fr;
  }

  @media (max-width: 540px) {
    grid-template-columns : 1fr;
  }
`

export const Board = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  width : 100%;
  padding: 0.8rem;
  border-radius: 10px;
  background-image: transparent;
  font-weight: 600;
  color: ${ colors.darkSection};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-size: cover;

  @media (max-width: 540px) {
    grid-template-columns : 1fr;
  }
`

export const BottomNavSection = styled.section`
    display: none;

    @media (max-width: 540px) {
      display: grid;
      background-color: white;
      border-top: 1px solid #df9600;
      font-weight: 600;
      color: ${colors.darkSection};
      width: 100%;
      position: fixed;
      bottom: 0;
      grid-template-columns : 1fr 1fr 1fr 1fr;
      padding: 0.4rem 0rem 0.2rem 0rem;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      margin : 0;
      z-index: 1;
    }
`

export const BlocBottomNavSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const UserProfil = styled.img`
    width: 25px;
    height: 25px;
    border-radius: 25px;
    border: 1px solid #f1f1f1;
`

export const LogoProfil = styled.img`
    width: 150px;
    height: 150px;
    border-radius: 150px;
    border: 1px solid #f1f1f1;
`

export const InfosUser = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @media (max-width: 540px) {
      flex-direction: column;
    }
`
export const PartnerSection = styled.section`
    padding: 3rem 4rem 1rem 4rem;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.6)
    ), url(${CAT});
    background-position: center center;
    margin : 0;
    width : 100%;
    background-size: cover;

    @media (max-width: 1080px) {
    padding: 1.5rem 1rem;
    }

    @media (max-width: 540px) {
        padding: 1.5rem 1rem;
    }
`

export const LogSection = styled.section`
    padding: 2rem 4rem 1rem 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-size: cover; 
    min-height: 100vh; 
    align-items: center;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.0),
      rgba(0, 0, 0, 0.6)
    ), url(${CAT});
    background-position: center center;
    margin : 0;
    width : 100%;

    @media (max-width: 1080px) {
      padding: 1.5rem 1rem;
    }

    @media (max-width: 540px) {
        padding: 1.5rem 1rem;
    }
`

export const EmailVerifySection = styled.section`
    padding: 1rem 4rem 1rem 4rem;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.6)
    ), url(${CAT});
    background-position: center center;
    margin : 0;
    width : 100%;
    background-size: cover;

    @media (max-width: 1080px) {
    padding: 1.5rem 1rem;
    }

    @media (max-width: 540px) {
        padding: 1.5rem 1rem;
    }
`

export const SpanSingleStanding = styled.span`
  position: relative;
  top: 0;
  background-color: transparent;
  font-size: 1.2rem;
`


export const SpanCartUpsell = styled.span`
  position: relative;
  color: #ffffff;
  padding: 10px;
  right: 0px;
  top: 0px;
  font-weight: 600;
  font-size: 0.8rem;
  background-color: green;
  border-radius: 3px 3px 0 0;
  text-transform: capitalize;
`