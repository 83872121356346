import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams  } from 'react-router-dom'
import 'react-phone-number-input/style.css'
import { useFetch22, useTheme } from '../../../hooks'
import Loading from '../../../composants/public/widgets/loading'
import { GridBoardForm, HeroSection, HomeLogo, LogSection, MainContainer, StyledLink, StyledTitle2 } from '../../../styles/atoms'
import Error from '../../../composants/public/widgets/error'
import PhoneInput from 'react-phone-number-input/input'
import Header from '../../../composants/public/sections/header'
import Footer from '../../../composants/public/sections/footer'
import MobileBottomHeader from '../../../composants/public/sections/mobileBottomHeader'
import logo from '../../../assets/logo.png'
import HelpButton from '../../../composants/public/widgets/helpButton'
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemType = 'IMAGE';

function AdsUpdate () {
    document.title = `BookEasily | Modifiez votre annonce`
    const x_xsrf_token = localStorage.getItem("cookies")
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null

    const { param_id } = useParams()
    const { theme } = useTheme()

    const [title, setTitle] = useState('')
    const [subtitle, setSubTitle] = useState('')
    const [description, setDescription] = useState('')
    const [max_num_person, setMaxNumPerson] = useState(1)
    const [commission, setCommission] = useState('')
    const [hosting_manager, setHostingManager] = useState('')
    const [value, setValue] = useState('')
    const [value2, setValue2] = useState('')
    const [prices_hour, setPriceHour] = useState('0')
    const [prices_day, setPriceDay] = useState('0')
    const [prices_days3, setPriceDays3] = useState('')
    const [prices_week, setPriceWeek] = useState('')
    const [prices_weeks2, setPriceWeeks2] = useState('')
    const [prices_month, setPriceMonth] = useState('0')
    const [boite, setBoite] = useState('')
    const [free_booking, setFreeBooking] = useState(false)
    const [n_rooms, setNRooms] = useState(1)
    const [fete, setFete] = useState(false)
    const [smoke, setSmoke] = useState(false)
    const [animal, setAnimal] = useState(false)
    const [free_cancellation_time, setFreeCancellationTime] = useState(0)
    const [perc_4cancellation, setPerc4cancellation] = useState(0)
    const [n_kitchens, setNKitchens] = useState(0)
    const [showers, setShowers] = useState(0)
    const [n_living, setNLiving] = useState(0)
    const [quantity, setQuantity] = useState(1)
    const [standing, setStanding] = useState(0)
    const [check_in_start, setCheckInStart] = useState('')
    const [check_out_start, setCheckOutStart] = useState('')
    const [check_in_end, setCheckInEnd] = useState('')
    const [check_out_end, setCheckOutEnd] = useState('')
    const [access, setAccess] = useState(false)
    const [parking, setParking] = useState(false)
    const [wifi, setWifi] = useState(false)
    const [cancelled, setCancelled] = useState('')
    const [booking_percentage, setBookingPercentage] = useState(0)
    const [zone, setZone] = useState(false)
    const [plage, setPlage] = useState(false)
    const [dejeuner, setDejeuner] = useState(false)
    const [with_disability, setWithDisability] = useState(false)
    const [n_beds, setNBeds] = useState(1)
    const [type, setType] = useState('')
    const [position, setPosition] = useState('')
    const [update, setUpdate] = useState([])
    const [status, setStatus] = useState('')
    const [advance_in_months, setAvanceInMonth] = useState(0)
    const [deposit_amount, setDepositAmount] = useState(0)
    const [deposit_in_months, setDepositInMonth] = useState(0)
    const [load, setLoad] = useState(0)
    const [data_error, setDataError] = useState('')
    const [photos, setPhotos] = useState([]);
    const [previewPhotos, setPreviewPhotos] = useState([]);

    useEffect(() => {
        const getData = async (param_id, token, x_xsrf_token) => {
          const response = await fetch(
            `https://api.book-easily.com/api/ads/${param_id}`, {
                method: "GET",
                mode: "cors",
                cache: "default",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': `${x_xsrf_token}`
                }
            }
          )
          const datas = await response.json()
          if (datas?.status === 1) {
            const data = datas?.data
            setUpdate(data ? data : localStorage.getItem("data_update"))
            localStorage.setItem("data_update", JSON.stringify(data))
            setStanding(data?.standing)
            setTitle(data?.name)
            setSubTitle(data?.designation)
            setNKitchens(data?.n_kitchens)
            setQuantity(data?.quantity)
            setMaxNumPerson(data?.max_num_person)
            setNLiving(data?.n_living_rooms)
            setNRooms(data?.n_bedrooms)
            setNKitchens(data?.n_kitchens)
            setShowers(data?.n_showers)
            setDescription(data?.description)
            setFreeBooking(data?.free_booking)
            setAccess(data?.practical_access)
            setParking(data?.free_parking)
            setWifi(data?.free_wifi)
            setFete(data?.have_party)
            setSmoke(data?.can_smoke)
            setAnimal(data?.with_animals)
            data?.free_cancellation === 1 || data?.free_cancellation === true ? setCancelled(false) : setCancelled(true)
            setBookingPercentage(data?.booking_perc)
            setPlage(data?.near_beach)
            setDejeuner(data?.free_breakfast)
            setZone(data?.near_tourist_area)
            setPriceDay(data?.prices.days)
            setPriceDays3(data?.prices.days3)
            setPriceHour(data?.prices.hours)
            setPriceWeek(data?.prices.weeks)
            setPriceWeeks2(data?.prices.weeks2)
            setPriceMonth(data?.prices.months)
            setType(data?.type)
            setHostingManager(data?.manager_full_name)
            setValue(data?.manager_phone)
            setValue2(data?.manager_phone2)
            setBoite(data?.post_boxe)
            setAvanceInMonth(data?.advance_in_months)
            setDepositInMonth(data?.deposit_in_months)
            setDepositAmount(data?.deposit_amount)
            setWithDisability(data?.with_disability)
            setPosition(JSON.parse(data?.map))
            setFreeCancellationTime(data?.free_cancellation_time)
            setPerc4cancellation(data?.perc_4cancellation)
            setPreviewPhotos(data?.cover)
          }
        }
        getData(param_id, token)
    }, [param_id, token])

    const { isLoading22, data21, data22, error22 } = useFetch22(
        `https://api.book-easily.com/api/streets`,
        `https://api.book-easily.com/api/commissions`,
        token
    )
    const streets = data21?.data
    const commissions = data22?.data
    
    let Allprices = {}
    if (prices_hour === '' || prices_hour === undefined) {
        setPriceHour(0)
    }
    if (prices_day === '' || prices_day === undefined) {
        setPriceDay(0)
    }
    if (prices_days3 === '' || prices_days3 === undefined) {
        setPriceDays3(0)
    }
    if (prices_week === '' || prices_week === undefined) {
        setPriceWeek(0)
    }
    if (prices_weeks2 === '' || prices_weeks2 === undefined) {
        setPriceWeeks2(0)
    }
    if (prices_month === '' || prices_month === undefined) {
        setPriceMonth(0)
    }
    prices_hour !== update?.prices?.hours && (Allprices.hours = prices_hour)
    prices_day !== update?.prices?.days && (Allprices.days = prices_day)
    prices_week !== update?.prices?.weeks && (Allprices.weeks = prices_week)
    prices_month !== update?.prices?.months && (Allprices.months = prices_month)

    const forms = new FormData()
    forms.append('commission_id', commission)

    title !== update.name && forms.append('title', title)
    description !== update.description && forms.append('description', description)
    n_rooms !== update.n_bedrooms && forms.append('n_bedrooms', n_rooms)
    quantity !== update.quantity && forms.append('quantity', quantity)

    n_rooms !== update.n_bedrooms && forms.append('n_bedrooms', n_rooms)
    max_num_person !== update.max_num_person && forms.append('max_num_person', max_num_person)
    JSON.stringify(Allprices) !== update.prices && forms.append('prices', JSON.stringify(Allprices))
    quantity !== update.quantity && forms.append('quantity', quantity)
    advance_in_months !== update.advance_in_months && forms.append('advance_in_months', advance_in_months)
    deposit_in_months !== update.deposit_in_months && forms.append('deposit_in_months', deposit_in_months)

    n_kitchens !== update.n_kitchens && forms.append('n_kitchens', n_kitchens)
    showers !== update.n_showers && forms.append('n_showers', showers)
    n_beds !== update.n_beds && forms.append('n_beds', n_beds)
    n_living !== update.n_living_rooms && forms.append('n_living_rooms', n_living)
    free_cancellation_time !== update.free_cancellation_time && forms.append('free_cancellation_time', free_cancellation_time) 
    perc_4cancellation !== update.perc_4cancellation && forms.append('perc_4cancellation', perc_4cancellation)
    booking_percentage !== update.booking_perc && forms.append('booking_perc', booking_percentage)
    value !== update.manager_phone && forms.append('manager_phone', value)
    value2 !== update.manager_phone2 && forms.append('manager_phone2', value2)
    hosting_manager !== update.manager_full_name && forms.append('manager_full_name', hosting_manager)
    boite !== update.post_boxe && forms.append('post_boxe', boite)
    position && forms.append('map', JSON.stringify(position))
    deposit_amount && forms.append('deposit_amount', deposit_amount)

    dejeuner === 1 || dejeuner === true ? forms.append('free_breakfast', 1) : forms.append('free_breakfast', 0)
    animal === 1 || animal === true  ? forms.append('with_animals', 1) : forms.append('with_animals', 0)
    fete === 1 || fete === true ? forms.append('have_party', 1) : forms.append('have_party', 0)
    smoke === 1 || smoke === true ? forms.append('can_smoke', 1) : forms.append('can_smoke', 0)
    zone === 1 || zone === true ? forms.append('near_tourist_area', 1) : forms.append('near_tourist_area', 0)
    plage === 1 || plage === true ? forms.append('near_beach', 1) : forms.append('near_beach', 0)
    wifi === 1 || wifi === true ? forms.append('free_wifi', 1) : forms.append('free_wifi', 0)
    parking === 1 || parking === true ? forms.append('free_parking', 1) : forms.append('free_parking', 0)
    access === 1 || access === true ? forms.append('practical_access', 1) : forms.append('practical_access', 0)
    with_disability === 1 || with_disability === true ? forms.append('with_disability', 1) : forms.append('with_disability', 0)
    cancelled === 0 || cancelled === false ? forms.append('free_cancellation', 1) : forms.append('free_cancellation', 0)
    
    check_in_start && forms.append('check_in_start', check_in_start)
    check_out_start && forms.append('check_out_start', check_out_start)
    check_in_end && forms.append('check_in_end', check_in_end)
    check_out_end && forms.append('check_out_end', check_out_end)

    if (free_booking === 1 || free_booking === true) {
        forms.append('free_booking', 1)
        forms.append('free_cancellation', 1)
        forms.append('free_cancellation_time', 0)
        forms.append('perc_4cancellation', 0)
    } else {
        forms.append('free_booking', 0)
    }

    const upload = (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 10) {
            alert('Vous pouvez sélectionner un maximum de 10 images.');
            return;
        }
        setPhotos(files);
        setPreviewPhotos(files.map(file => URL.createObjectURL(file)));
    };

    const moveImage = (dragIndex, hoverIndex) => {
        const newPhotos = [...photos];
        const newPreviewPhotos = [...previewPhotos];
        const [draggedPhoto] = newPhotos.splice(dragIndex, 1);
        const [draggedPreview] = newPreviewPhotos.splice(dragIndex, 1);
        newPhotos.splice(hoverIndex, 0, draggedPhoto);
        newPreviewPhotos.splice(hoverIndex, 0, draggedPreview);
        setPhotos(newPhotos);
        setPreviewPhotos(newPreviewPhotos);
    };
     
    async function handleSubmit(e, forms, token, x_xsrf_token, param_id) {
        e.preventDefault() 
        setLoad(1)

        if (photos) {
            if (photos.length < 10) {
                for (let i = 0; i < photos.length; i++) {
                    forms.append('images[]', photos[i])
                }
            } else {
                for (let i = 0; i < 10; i++) {
                    forms.append('images[]', photos[i])
                }
            }
        }

        await fetch(`https://api.book-easily.com/api/ads/${param_id}`, {
            method: "POST",
            mode: "cors",
            cache: "default",
            credentials: "include",
            redirect: 'manual',
            body: forms,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json; charset=UTF-8',
                'X-XSRF-TOKEN': `${x_xsrf_token}`,
            },
        })
        .then((response) => response.json())
        .then((data) => {
            setStatus(data?.status)
            setLoad(0)
            if (data?.status === 1) {
                window.location.assign("/dashboard") 
            } else {
                setDataError(data?.errors)
            }
        })
        .catch((err) => {
            setLoad(0)
            console.log(err.message)
        })
    } 

    if (error22) {
    return (
        <Error />
    )
    }

    return isLoading22 ? (
        <Loading />
        ) : (
            user.role_id === 3 ?
            <Fragment>
                
                { user.email_verified === 0 && 
                    (<div className="alert alert-danger text-center" role="alert">
                        Votre compte n'est vérifié, consultez vos mails pour le faire ou contactez le service clients !
                    </div>)
                }
                <LogSection>
                    <form className="my__form g-2 p-3 my-5 col-md-8" method="POST" onSubmit={(e) => handleSubmit(e, forms, token, x_xsrf_token, param_id) }>
                        <div className="col-md-12 text-center">
                            <Link to="/dashboard">
                                <HomeLogo src={logo} alt="logo BookEasily" />
                            </Link>
                        </div>
                        <div className="col-md-12 my-1">
                            <StyledTitle2 className='text-black'>Modifiez votre annonce | { subtitle } - { update.street }</StyledTitle2>
                        </div>
                        { status === 1 && 
                            (<div className="alert alert-info text-center" role="alert">
                                Votre annonce a été modifiée avec succès ! Voir votre <Link to='/dashboard' >tableau de bord</Link>. 
                            </div> )
                        }
                        <div className="col-md-12 my-1">
                            <i className="bi bi-box-arrow-up-left text-warning"></i> <Link to="/dashboard" className='text-warning'> Accueil</Link>
                        </div>
                        <GridBoardForm>
                            <div className="col">
                                <input type="text" className="form-control rounded-5" disabled id="subtitle" name="subtitle" maxLength={50} value={subtitle} placeholder="Nom de l'hébergement..." onChange={(e) => setSubTitle(e.target.value) } />
                                <small className="text-muted">Nom de l'hébergement</small>
                                { data_error.designation && 
                                    (<div className="alert alert-danger text-center my-1" role="alert">
                                        {data_error.designation}
                                    </div>)
                                }
                            </div>
                            <div className="col">
                                <input type="text" className="form-control rounded-5" id="title" name="title" maxLength={150} value={title} placeholder="Decrire votre logement en quelques mots..." onChange={(e) => setTitle(e.target.value) } />
                                <small className="text-muted">Courte description</small>
                                { data_error.name && 
                                    (<div className="alert alert-danger text-center my-1" role="alert">
                                        {data_error.name}
                                    </div>)
                                }
                            </div>
                            { update.category !== "non meublés" & update.category !== "meublés" ? 
                            <div className="col">
                                <input type="number" className="form-control rounded-5" disabled value={standing} id="standing" name="standing" onChange={(e) => setStanding(e.target.value) } min='1' max='5' placeholder="Standing (nombre d'étoiles)..."/>
                                <small className="text-muted">Standing</small>
                                { data_error.standing && 
                                    (<div className="alert alert-danger text-center my-1" role="alert">
                                        {data_error.standing}
                                    </div>)
                                }
                            </div>
                            : null }
                            <div className="col">
                                <input type="number" className="form-control rounded-5" id="quantity" name="quantity" min='0' value={quantity} onChange={(e) => setQuantity(e.target.value) } placeholder="Quantité à publier sur BookEasily" />
                                <small className="text-muted">Quantité de logement à publier sur BookEasily </small>
                                { data_error.quantity && 
                                    (<div className="alert alert-danger text-center my-1" role="alert">
                                        {data_error.quantity}
                                    </div>)
                                }
                            </div>
                            <div className="col">
                                <input type="text" className="form-control rounded-5" id="hosting_manager" name="hosting_manager" value={hosting_manager} placeholder="Nom du responsable..." onChange={(e) => setHostingManager(e.target.value) }/>
                                <small className="text-muted">Nom du responsable</small>
                                { data_error.manager_full_name && 
                                    (<div className="alert alert-danger text-center my-1" role="alert">
                                        {data_error.manager_full_name}
                                    </div>)
                                }
                            </div>
                            <div className="col">
                                <PhoneInput id="phone" name="phone" className="form-control rounded-5"
                                    placeholder="Indicatif de votre pays + numéro de la reception..."
                                    defaultCountry='CM'
                                    value={value}
                                    onChange={setValue}/>
                                <small className="text-muted">Numéro Whatsapp de la reception</small>
                                { data_error.manager_phone && 
                                    (<div className="alert alert-danger text-center my-1" role="alert">
                                        {data_error.manager_phone}
                                    </div>)
                                }
                            </div>
                            <div className="col">
                                <PhoneInput id="phone2" name="phone2" className="form-control rounded-5"
                                    placeholder="Indicatif de votre pays + numéro de la reception..."
                                    defaultCountry='CM'
                                    value={value2}
                                    onChange={setValue2} />
                                <small className="text-muted">Autre numéro de la reception</small>
                                { data_error.manager_phone2 && 
                                    (<div className="alert alert-danger text-center my-1" role="alert">
                                        {data_error.manager_phone2}
                                    </div>)
                                }
                            </div>
                        </GridBoardForm>
                        
                        <hr className="text-warning"/>
        
                        <fieldset className='my-2 gy-5'>
                            <GridBoardForm>
                                <div className="col-sm-12 py-1">
                                    <select className="form-select rounded-5" id="street" disabled name="street" >
                                        <option value=''>Quartier...</option>
                                        {streets.map((street) => (
                                        <option key={ `${street.name}-${street.id}` } value={street.id}>{street.name}</option>
                                        ))}
                                    </select>
                                    <small className="text-muted">Quartier : <span className='text-warning'>{update.street} </span> </small>
                                    { data_error.street && 
                                        (<div className="alert alert-danger text-center my-1" role="alert">
                                            {data_error.street}
                                        </div>)
                                    }
                                </div>
                                <div className="col-sm-12 py-1">
                                    <input type="url" className="form-control rounded-5" id="position" name="position" value={position} placeholder="lien map (Ex: https://...)" onChange={(e) => setPosition(e.target.value) }/>
                                    <small className="text-muted">Lien Map (Ex: https://...)</small>
                                </div>
                                <div className="col-sm-12 py-1">
                                    <input type="text" className="form-control rounded-5" id="boite" name="boite" value={boite} placeholder="Boite postale..." onChange={(e) => setBoite(e.target.value) } />
                                    <small className="text-muted">Boite postale</small>
                                    { data_error.post_boxe && 
                                        (<div className="alert alert-danger text-center my-1" role="alert">
                                            {data_error.post_boxe}
                                        </div>)
                                    }
                                </div>
                            </GridBoardForm>
                            <GridBoardForm>
                                <div className="form-check py-1">
                                    <input className="form-check-input" type="checkbox" checked={access} id="access" name='access' onChange={(e) => setAccess(e.target.checked) } />
                                    <label className="form-check-label" htmlFor="access">
                                        Accès praticable
                                    </label>
                                </div>
                                <div className="form-check py-1">
                                    <input className="form-check-input" type="checkbox" checked={with_disability} id="with_disability" name='with_disability' onChange={(e) => setWithDisability(e.target.checked) } />
                                    <label className="form-check-label" htmlFor="with_disability">
                                        Accessible aux handicapés
                                    </label>
                                </div>
                                <div className="form-check py-1">
                                    <input className="form-check-input" type="checkbox" checked={zone} id="zone" name='zone' onChange={(e) => setZone(e.target.checked) }/>
                                    <label className="form-check-label" htmlFor="zone">
                                        Proche d'un site tourristique
                                    </label>
                                </div>
                                <div className="form-check py-1">
                                    <input className="form-check-input" type="checkbox" checked={plage} id="plage" name='plage' onChange={(e) => setPlage(e.target.checked) }/>
                                    <label className="form-check-label" htmlFor="plage">
                                        Proche d'une plage
                                    </label>
                                </div>
                            </GridBoardForm>
                        </fieldset>
        
                        <hr className="text-warning"/>
                        
                        <fieldset className='my-2 gy-5'>
                            <GridBoardForm>
                                { update.category !== "non meublés" ? 
                                <Fragment>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="max_num_person" min='1' name="max_num_person" value={max_num_person} placeholder="Nombre maximum de personne..." onChange={(e) => setMaxNumPerson(e.target.value) } />
                                        <small className="text-muted">Nombre maximum de personnes</small>
                                        { data_error.max_num_person && 
                                            (<div className="alert alert-danger text-center my-1" role="alert">
                                                {data_error.max_num_person}
                                            </div>)
                                        }
                                    </div>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="n_beds" name="n_beds" min='0' value={n_beds} placeholder="Nombre de lit..." onChange={(e) => setNBeds(e.target.value) } />
                                        <small className="text-muted">Nombre de lits par chambre</small>
                                        { data_error.n_beds && 
                                            (<div className="alert alert-danger text-center my-1" role="alert">
                                                {data_error.n_beds}
                                            </div>)
                                        }
                                    </div>
                                </Fragment>
                                : null }
                                { (type === 'chambre communicante' || type === 'suite exécutive' || type === 'suite junior' || type === 'suite présidentielle' || type === 'appartement' || type === 'villa' || type === 'maison de campagne' || type === 'duplex') ? (
                                <Fragment>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="n_living" name="n_living" min='0' value={n_living} placeholder="Nombre de salons..." onChange={(e) => setNLiving(e.target.value) } />
                                        <small className="text-muted">Nombre de salons</small>
                                    </div>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="n_rooms" name="n_rooms" min='0' value={n_rooms} placeholder="Nombre de chambres..." onChange={(e) => setNRooms(e.target.value) } />
                                        <small className="text-muted">Nombre de chambres</small>
                                        { data_error.n_bedrooms && 
                                            (<div className="alert alert-danger text-center my-1" role="alert">
                                                {data_error.n_bedrooms}
                                            </div>)
                                        }
                                    </div>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="n_kitchens" name="n_kitchens" min='0' value={n_kitchens} placeholder="Nombre de cuisines..." onChange={(e) => setNKitchens(e.target.value) } />
                                        <small className="text-muted">Nombre de cuisines</small>
                                    </div>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="showers" name="showers" min='0' value={showers} placeholder="Nombre de douches modernes..." onChange={(e) => setShowers(e.target.value) } />
                                        <small className="text-muted">Nombre de douches</small>
                                    </div>
                                </Fragment>
                                ) : null }
                            </GridBoardForm>
                            <div className="col-12 py-1">
                                <textarea rows='10' className='form-control rounded-3' id='description' name='description' value={description} placeholder="Description de votre immobilier, lieu exact de l'hebergement, lieu attractif, les avantages, etc..." onChange={(e) => setDescription(e.target.value) } ></textarea>
                                <small className="text-muted">Description de votre immobilier : Ne pas mettre de photo, de numero de telephone et d'email </small>
                                { data_error.description && 
                                    (<div className="alert alert-danger text-center my-1" role="alert">
                                        {data_error.description}
                                    </div>)
                                }
                            </div>
                            <GridBoardForm>
                                <div className="form-check py-1">
                                    <input className="form-check-input" type="checkbox" id="parking" name='parking' checked={parking} onChange={(e) => setParking(e.target.checked) }/>
                                    <label className="form-check-label" htmlFor="parking">
                                        Parking gratuit
                                    </label>
                                </div>
                                { update.category !== "non meublés" ? 
                                <Fragment>
                                    <div className="form-check py-1">
                                        <input className="form-check-input" type="checkbox" id="wifi" name='wifi' checked={wifi} onChange={(e) => setWifi(e.target.checked) }/>
                                        <label className="form-check-label" htmlFor="wifi">
                                            WIFI gratuit
                                        </label>
                                    </div> 
                                    <div className="form-check py-1">
                                        <input className="form-check-input" type="checkbox" checked={dejeuner} id="dejeuner" name='dejeuner' onChange={(e) => setDejeuner(e.target.checked) }/>
                                        <label className="form-check-label" htmlFor="dejeuner">
                                            Petit déjeuner offert
                                        </label>
                                    </div>
                                    <div className="form-check py-1">
                                        <input className="form-check-input" type="checkbox" id="fete" name='fete' checked={fete} onChange={(e) => setFete(e.target.checked) }/>
                                        <label className="form-check-label" htmlFor="fete">
                                            Fete autorisée
                                        </label>
                                    </div>
                                </Fragment>
                                : null }
                                <div className="form-check py-1">
                                    <input className="form-check-input" type="checkbox" id="smoke" name='smoke' checked={smoke} onChange={(e) => setSmoke(e.target.checked) }/>
                                    <label className="form-check-label" htmlFor="smoke">
                                        Autorisation de fumée
                                    </label>
                                </div>
                                <div className="form-check py-1">
                                    <input className="form-check-input" type="checkbox" id="animal" name='animal' checked={animal} onChange={(e) => setAnimal(e.target.checked) }/>
                                    <label className="form-check-label" htmlFor="animal">
                                        Animaux acceptés
                                    </label>
                                </div>
                            </GridBoardForm>
                            <GridBoardForm>
                                <div className="col-sm-12 py-1">
                                    <div className="input-group rounded-5">
                                        <div className="input-group-text">De</div>
                                        <input type="time" className="form-control " id="check_in" name="check_in_start" value={check_in_start} onChange={(e) => setCheckInStart(e.target.value) } />
                                    </div>
                                    <small className="text-muted">Heure d'arrivée : {update.check_in} </small>
                                </div>
                                <div className="col-sm-12 py-1">
                                    <div className="input-group rounded-5">
                                        <div className="input-group-text">A</div>
                                        <input type="time" className="form-control " id="check_in_end" name="check_in_out" value={check_in_end} onChange={(e) => setCheckInEnd(e.target.value) } />
                                    </div>
                                    <small className="text-muted">Heure d'arrivée : {update.check_in}</small>
                                </div>
                                <div className="col-sm-12 py-1">
                                    <div className="input-group rounded-5">
                                        <div className="input-group-text">De</div>
                                        <input type="time" className="form-control" id="check_out_start" name="check_out_start" value={check_out_start} onChange={(e) => setCheckOutStart(e.target.value) } />
                                    </div>
                                    <small className="text-muted">Heure de départ : {update.check_out}</small>
                                </div>
                                <div className="col-sm-12 py-1">
                                    <div className="input-group rounded-5">
                                        <div className="input-group-text">A</div>
                                        <input type="time" className="form-control " id="check_out_end" name="check_out_end" value={check_out_end} onChange={(e) => setCheckOutEnd(e.target.value) } />
                                    </div>
                                    <small className="text-muted">Heure de départ : {update.check_out}</small>
                                </div>
                            </GridBoardForm>
                            <DndProvider backend={HTML5Backend}>
                                <div>
                                    <div className="col-12 py-1">
                                        <input 
                                            type="file" 
                                            accept="image/*" 
                                            className="form-control rounded-5" 
                                            id="photo" 
                                            name="photo" 
                                            multiple 
                                            onChange={upload} 
                                        />
                                        <small className="text-warning">Maximum 10 images *</small>
                                    </div>
                                    <p>Choisissez votre image principale</p>
                                    <div className="preview-container">
                                        {previewPhotos.map((src, index) => (
                                            <ImagePreview 
                                                key={index} 
                                                src={src} 
                                                index={index} 
                                                moveImage={moveImage} 
                                            />
                                        ))}
                                    </div>
                                </div>
                            </DndProvider>
                        </fieldset>
        
                        <hr className="text-warning"/>
                        
                        <fieldset className='my-2 gy-5'>
                            <GridBoardForm>
                                { update.category !== "non meublés" ? 
                                <Fragment>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="prices_hour" min='0' name="prices_hour" value={prices_hour} placeholder="Tarif horaire" onChange={(e) => setPriceHour(e.target.value) } />
                                        <small className="text-muted">Tarif pour une heure</small>
                                    </div>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="prices_day" min='0' name="prices_day" value={prices_day} placeholder="Tarif journalier" onChange={(e) => setPriceDay(e.target.value) }/>
                                        <small className="text-muted">Tarif pour une nuitée (24 Heures) </small>
                                    </div>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="prices_days3" min='0' value={prices_days3} name="prices_days3" placeholder="Tarif 3 jours" onChange={(e) => setPriceDays3(e.target.value) }/>
                                        <small className="text-muted">Tarif pour 3 jours</small>
                                    </div>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="prices_week" min='0' name="prices_week" value={prices_week} placeholder="Tarif hebdomadaire" onChange={(e) => setPriceWeek(e.target.value) }/>
                                        <small className="text-muted">Tarif pour une semaine (7 jours)</small>
                                    </div>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="prices_weeks2" min='0' value={prices_weeks2} name="prices_weeks2" placeholder="Tarif 14 jours" onChange={(e) => setPriceWeeks2(e.target.value) }/>
                                        <small className="text-muted">Tarif pour 2 semaines (14 jours)</small>
                                    </div>
                                </Fragment>
                                : null }
                                <div className="col-sm-12 py-1">
                                    <input type="number" className="form-control rounded-5" id="prices_month" min='0' name="prices_month" value={prices_month} placeholder="Tarif mensuel" onChange={(e) => setPriceMonth(e.target.value)} />
                                    <small className="text-muted">Tarif pour un mois (30 jours)</small>
                                </div>
                                { update.category === "meublés" ? 
                                <div className="col-sm-12 py-1">
                                    <input type="number" className="form-control rounded-5" id="deposit_amount" min='0' value={deposit_amount} name="deposit_amount" placeholder="Caution" onChange={(e) => setDepositAmount(e.target.value)} />
                                    <small className="text-muted">Caution remboursable</small>
                                </div>
                                : null }
                                { update.category !== "non meublés" ? 
                                <div className="col-sm-12 py-1">
                                    <select className="form-select rounded-5" id="commission" name="commission" onChange={(e) => setCommission(e.target.value) } >
                                        <option value=''>Commission...</option>
                                        {commissions.map((commission) => (
                                            <option key={ `${commission.perc}-${commission.id}` } value={commission.id}>{commission.perc}</option>
                                        ))}
                                    </select>
                                    <small className="text-muted">Commission : {update.commission}</small>
                                </div>
                                : null }
                                { update.category === "non meublés" ? 
                                <Fragment>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="n_month" name="n_month" min='0' value={advance_in_months} onChange={(e) => setAvanceInMonth(e.target.value)} placeholder="Nombre de mois à l'avance..."/>
                                        <small className="text-muted">Nombre de mois à l'avance</small>
                                    </div>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="n_caution" name="n_caution" min='0' value={deposit_in_months} onChange={(e) => setDepositInMonth(e.target.value)} placeholder="Nombre de mois de caution..."/>
                                        <small className="text-muted">Nombre de mois de caution</small>
                                    </div>
                                </Fragment>
                                : null }
                                <div className="form-check py-1">
                                    <input className="form-check-input" type="checkbox" id="free_booking" name='free_booking' checked={free_booking} onChange={(e) => setFreeBooking(e.target.checked) } />
                                    <label className="form-check-label" htmlFor="free_booking">
                                        Réservation gratuite
                                    </label>
                                </div>
                                { free_booking  === false || free_booking === 0 ?
                                    (
                                    <Fragment>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="booking_percentage" name="booking_percentage" value={booking_percentage} onChange={(e) => setBookingPercentage(e.target.value) } placeholder="Pourcentage à verser par le client pour une reservation payante (0% - 30 %)" min='0' max='30' />
                                        <label className="text-muted">Pourcentage à verser par le client pour une reservation payante (0% - 30 %)</label>
                                        { data_error.booking_perc && 
                                            (<div className="alert alert-danger text-center my-1" role="alert">
                                                {data_error.booking_perc}
                                            </div>)
                                        }
                                    </div>
                                    <div className="form-check py-1">
                                        <input className="form-check-input" type="checkbox" id="cancelled" name='cancelled' checked={cancelled} onChange={(e) => setCancelled(e.target.checked) }/>
                                        <label className="form-check-label" htmlFor="cancelled">
                                            Annulation payante
                                        </label>
                                    </div>
                                    
                                
                                { cancelled === true || cancelled === 1 ? 
                                    (
                                    <Fragment>
                                        <div className="col-sm-12 py-1">
                                            <input type="number" className="form-control rounded-5" id="free_cancellation_time" name="free_cancellation_time" value={free_cancellation_time} placeholder="Delai d'annulation gratuite (nombre de jours a l'avance)" onChange={(e) => setFreeCancellationTime(e.target.value) } />
                                            <label className="text-muted">Délai d'annulation gratuite avant l'arrivée du client (en jours)</label>
                                            { data_error.free_cancellation_time && 
                                                (<div className="alert alert-danger text-center my-1" role="alert">
                                                    {data_error.free_cancellation_time}
                                                </div>)
                                            }
                                        </div>                               
                                        <div className="col-sm-12 py-1">
                                            <input type="number" className="form-control rounded-5" id="perc_4cancellation" name="perc_4cancellation" value={perc_4cancellation} placeholder="Pourcentage de remboursement au client (0% - 100 %)" min='0' max='100' onChange={(e) => setPerc4cancellation(e.target.value) } />
                                            <label className="text-muted">Pourcentage à rembourser au client en cas d'annulation</label>
                                            { data_error.perc_4cancellation && 
                                                (<div className="alert alert-danger text-center my-1" role="alert">
                                                    {data_error.perc_4cancellation}
                                                </div>)
                                            }
                                        </div>
                                    </Fragment>
                                    ) : null }

                                    </Fragment>
                                    )
                                : null }
                            </GridBoardForm>
                            
                        </fieldset>
        
                        <hr className="text-warning"/>

                        <div className="form-check py-3">
                            <input className="form-check-input" disabled defaultChecked type="checkbox" id="al" name='al' />
                            <label className="form-check-label" htmlFor="al">
                                J'atteste qu'il s'agit d'une activité légale et que je suis le propriétaire/gérant légal de cet hébergement *
                            </label>
                        </div>
                        <div className="form-check py-2">
                            <input className="form-check-input" disabled defaultChecked type="checkbox" id="cg" name='cg'  />
                            <label className="form-check-label" htmlFor="cg">
                                J'ai lu et j'accepte les conditions générales de ventes *
                            </label>
                        </div>
                        <div className="col-12 my-1 text-warning">
                            Aucun champ obligatoire
                        </div>
                        <div className="col-12 my-1 text-end">
                            <StyledLink theme={theme} to={ `/about#contact` } >Besoin d'aide ?</StyledLink>
                        </div>
                        
                        { status === 0 && 
                            (<div className="alert alert-danger text-center" role="alert">
                                Vérifiez à nouveau vos informations. 
                            </div>)
                        }

                        <div className="col-12">
                            { load === 0 ? 
                                <button type="submit" className="btn btn-outline-warning fw-bold w-100 my-3 rounded-5">{ status === 1 ? <span>Opération réussie ✔</span>  : <span>Modifiez votre annonce</span>  }</button>
                            :
                                <button className="btn btn-outline-warning fw-bold w-100 my-3 rounded-5" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> En cours...
                                </button>
                            }
                        </div>
                    </form>
                </LogSection> 
            </Fragment>
            :
            <Fragment>
            <Header />
            <MainContainer theme={theme}>
                <HeroSection>
                    <div className="py-4 text-center text-white">
                        <h2 className="display-5 fw-bold">Impossible !</h2>
                        <div className="col-lg-6 mx-auto">
                            <p className="lead mb-4">Vous devez avoir un compte partenaire pour ajouter une annonce</p>
                            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                                <button type="button" to="/sign-up" className="btn btn-warning  fw-bold px-4">Créer un compte partenaire</button>
                            </div>
                        </div>
                    </div>
                </HeroSection>
            </MainContainer>
            <Footer />
            <HelpButton />
            <MobileBottomHeader/>
        </Fragment>
        )
}

const ImagePreview = ({ src, index, moveImage }) => {
    const ref = React.useRef(null);

    const [, drop] = useDrop({
        accept: ItemType,
        hover(item) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            moveImage(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div 
            ref={ref} 
            className="preview-item" 
            style={{ opacity: isDragging ? 0.5 : 1 }}
        >
            <img src={src} alt={`preview ${index}`} className="preview-image" />
        </div>
    );
};

export default AdsUpdate