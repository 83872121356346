import React, { useEffect, useState } from 'react';
import { Button } from './styles/stylesHelpButton';

const HelpButton = () => {
  const [visible, setVisible] = useState(false);

  // Fonction pour vérifier la visibilité du bouton en fonction du défilement
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    setVisible(scrolled > 100);
  };

  useEffect(() => {
    // Ajout de l'écouteur d'événement de défilement
    window.addEventListener('scroll', toggleVisible);

    // Nettoyage de l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  return (
    <Button
      href="https://wa.me/237695931631"
      className="fw-bold shadow bg-warning"
      style={{
        display: visible ? 'block' : 'none',
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        transition: 'opacity 0.3s ease', // Ajout d'une transition pour l'animation
        opacity: visible ? 1 : 0, // Animation de l'opacité
      }}
    >
     ?
    </Button>
  );
};

export default HelpButton;
